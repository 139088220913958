import axios from "./BaseService";

const CONTATOS_URL = `${process.env.REACT_APP_API_URL}/contatos/`;

export async function getContatos(search, page, email, token) {
  const contatosUrl = `${CONTATOS_URL}?search=${search}&page=${page}&email=${email}`;

  const headers = { authorization: token };
  const response = await axios.get(contatosUrl, { headers });
  return response.data; //{count, rows}
}

export async function getAcompanhantes(token) {
  const visitantesUrl = `${CONTATOS_URL}/acompanhantes`;

  const headers = { authorization: token };
  const response = await axios.get(visitantesUrl, { headers });
  return response.data; //{count, rows}
}

export async function getPessoasPorEstado(token) {
  const data = `${CONTATOS_URL}/estado-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getPessoasPorRegiao(token) {
  const data = `${CONTATOS_URL}/regiao-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getPessoasPossuiBarco(token) {
  const data = `${CONTATOS_URL}/possui-barco-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getMarcaBarcos(token) {
  const data = `${CONTATOS_URL}/marca-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getTamanhoBarcos(token) {
  const data = `${CONTATOS_URL}/tamanho-barco-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getPessoasPorSexo(token) {
  const data = `${CONTATOS_URL}/sexo-dashboard`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getContatosPorEvento(
  token,
  id = "",
  dataInicial = "",
  dataFinal = ""
) {
  const data = `${CONTATOS_URL}/evento?evento=${id}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getContatosPorEventoDashboard(
  token,
  id = "",
  dataInicial = "",
  dataFinal = ""
) {
  const data = `${CONTATOS_URL}/evento-dashboard?evento=${id}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

  const headers = { authorization: token };
  const response = await axios.get(data, { headers });
  return response.data; //{count, rows}
}

export async function getContato(id, token) {
  const headers = { authorization: token };
  const response = await axios.get(`${CONTATOS_URL}${id}`, { headers });
  return response.data;
}

export async function saveContato(id, newContato, token) {
  const headers = { authorization: token };
  let response;
  if (id)
    response = await axios.patch(`${CONTATOS_URL}${id}`, newContato, {
      headers,
    });
  else response = await axios.post(CONTATOS_URL, newContato, { headers });
  return response.data;
}

export async function deleteContato(id, token) {
  const headers = { authorization: token };
  const response = await axios.delete(`${CONTATOS_URL}${id}`, { headers });
  return response.data;
}

export async function sendEmail(id, envEmail, token) {
  const headers = { authorization: token };
  const response = await axios.post(
    `${CONTATOS_URL}${id}?envEmail=${envEmail}`,
    { headers }
  );
  return response.data;
}

export async function sendWhatsapp({
  id = 1,
  email = "",
  token = "",
  celular = "",
  nome = "",
}) {
  const headers = { authorization: token };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/wpp`,
      {
        message: {
          phone: `55${celular}`,
          message: 
`Azimut Yachts

Seja bem vindo a bordo!
Seu ingresso para o Rio Boat Show 2025 já está disponível!
Apresente este QR Code na portaria VIP da Azimut para acessar o evento.


MARINA DA GLÓRIA
Avenida Infante, Dom Henrique, S/N, Cep: 20021-140
Glória – Rio de Janeiro - RJ - Brasil
26 de abril a 05 de maio de 2025.

Horário:
  26,28,29,30/04, 01 e 02/05 - 15h às 22h.
  27/04 e 03/05 - 13h às 22h.
  04/05 - 13h às 21h.`,
        },
        qrCodeData: { id },
      },
      { headers }
    );
    return response.data;
  } catch (error) {
    alert(error.data);
  }
}
