import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import {
  sendEmail,
  saveContato,
  sendWhatsapp,
} from "../../services/ContatosService";
import { getEventos } from "../../services/EventoService";
import { savePresenca } from "../../services/PresencaService";
import Toast from "../../components/Toast/Toast";
import { jsPDF } from "jspdf";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/**
 * props:
 * - data
 * - onEditClick
 */
function ContatoRow(props) {
  const DEFAULT_CONFIRMAR = {
    id: 0,
    contato: "",
    evento: "",
    acompanhantes: 0,
    aceite: "S",
  };

  let urlImagem;
  const opts = {
    errorCorrectionLevel: "H",
    type: "terminal",
    quality: 0.95,
    margin: 1,
    color: {
      dark: "#208698",
      light: "#FFF",
    },
  };

  const [notification, setNotification] = useState({});
  const [error, setError] = useState("");
  const [evento, setEventos] = useState([]);
  const [proximo, setProximo] = useState([]);
  const [confirmar, setConfirmar] = useState(DEFAULT_CONFIRMAR);
  const [contato, setContato] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEventos(proximo || 1, token)
      .then((result) => {
        setEventos(result);
        //setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }, []);

  function getActiveClass(isActive) {
    return isActive ? "text-success" : "text-danger";
  }

  function getActiveText(isActive) {
    return isActive ? "RUNNING" : "STOPPED";
  }

  function enviarEmail(event) {
    console.log("Enviar Email");
    const envEmail = true;
    const id = event.target.id.replace("email", "");
    const token = localStorage.getItem("token");
    // sendEmail(props.data.id, envEmail, token)
    //     .then(x => { setNotification({ type: 'success', text: 'e-mail enviado com sucesso!!'  }); })
    //     .catch(err => {
    //         console.error(err.response ? err.response.data : err.message);
    //         setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //     });

    saveContato(props.data.id, props.data, token)
      .then((result) => {
        // console.log('Salva Contato ', props.data);

        return setNotification({ type: "success", text: `e-mail enviado` });
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.message);
      });
  }

  function enviarWhatsapp() {
    const token = localStorage.getItem("token");
    sendWhatsapp({
      token,
      celular: props.data.celular,
      email: props.data.email,
      id: props.data.id,
      nome: props.data.nome,
    });
    // console.log("nao faco nada ainda");
    setNotification({ type: "success", text: `Convite por whatsapp enviado!` });
    console.log("data is", props.data);
  }

  function gerarPDF(event) {
    QRCode.toDataURL(props.data.email, opts).then((qrImage) => {
      urlImagem = qrImage;

      const img = urlImagem.replace(/^data:image\/png;base64,/, "");
      //         //  const bufferLogo = new Buffer.from('./Azimut.png','png');
      const buffer = Buffer.from(img, "base64");

      const pdf = new jsPDF("p", "pt");

     // pdf.addImage(`../img/azimut_black.png`, 'png', 250, 300, 100, 100);

      // pdf.addImage(`${__dirname}/img/azimut_black.png`, {
      //   fit: [250, 300],
      //   align: 'center',
      //   valign: 'top'  // Defina a imagem para ficar no topo
      // });

      pdf.addFont("helvetica", "normal");
      pdf.text(100, 20, "Ingresso Rio Boat Show 2025");
      pdf.text(`Azimut Yachts

Seja bem vindo a bordo!
Seu ingresso para o Rio Boat Show 2025 já está disponível!
Apresente este QR Code na portaria VIP da Azimut para 
acessar o evento.
-----------------------------------------------------------------------------
MARINA DA GLÓRIA
Avenida Infante, Dom Henrique, S/N, Cep: 20021-140
Glória – Rio de Janeiro - RJ - Brasil
26 de abril a 05 de maio de 2025.

Horário:
  26,28,29,30/04, 01 e 02/05 - 15h às 22h.
  27/04 e 03/05 - 13h às 22h.
  04/05 - 13h às 21h.
              
${props.data.id} - ${props.data.nome}

            `,
        100,
        50
      );

      pdf.setFontSize(15);

      pdf.addImage(buffer, "png", 100, 350, 80, 80);
      pdf.save("generated.pdf");
    });
  }

  function confirmarPresenca(event) {
    const token = localStorage.getItem("token");
    confirmar.id = 0;
    confirmar.contato = props.data.id;
    confirmar.evento = evento.id;
    console.log("Salva presença ", confirmar);
    savePresenca(0, confirmar, token)
      .then((result) => {
        //  btnClose.current.click();
        console.log("Salva presença ", confirmar);
        //   if (props.onSubmit) props.onSubmit(result);
        return setNotification({
          type: "success",
          text: `Presença Confirmada com sucesso!!`,
        });
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.message);
      });
  }

  return (
    <React.Fragment>
      <tr>
        <td>
          <button
            className="btn btn-xs ms-2"
            title="Enviar Convite Whatsapp"
            onClick={enviarWhatsapp}
            style={{ backgroundColor: "#F0BC74" }}
          >
            <WhatsAppIcon/>
            

            {/* Editar */}
          </button>
          {props.data.id > 0 ? (
            <button
              id={"edit" + props.data.id}
              type="button"
              className="btn btn-xs ms-2"
              title="Editar o Contato"
              data-bs-toggle="modal"
              data-bs-target="#modalContato"
              onClick={(event) => {
                props.onEditClick(event, props.data);
              }}
              style={{ backgroundColor: "#F0BC74" }}
            >
              <svg
                id={"edit" + props.data.id}
                className="icon icon-xs"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                ></path>
              </svg>
              {/* Editar */}
            </button>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {/* </td>   
            {/* <td>
                {  
                    props.data.id > 0
                        ? <button id={"edit" + props.data.id} type="button" className="btn btn-secondary btn-sm ms-2" title="Confirmar Presença" onClick={confirmarPresenca}>                            
                        {/* <button id={"edit" + props.data.id} type="button" className="btn btn-primary btn-sm animate-up-2" title="Confirmar Presença" data-bs-toggle="modal" data-bs-target="#modalConfirma" onClick={props.onConfirmaClick}>                            
                                 <svg id={"edit" + props.data.id} className="icon icon-xs" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"  onClick={props.onEditClick}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
                                </svg> 
                                Confirmar
                            </button> 
                        : <React.Fragment></React.Fragment>
                }
            </td> *
            <td> */}
          {props.data.id > 0 ? (
            <button
              id={"email" + props.data.id}
              type="button"
              className="btn btn-xs ms-2"
              title="Enviar e-mail"
              onClick={enviarEmail}
              style={{ backgroundColor: "#F0BC74" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-xs"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                onClick={enviarEmail}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </button>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {/* </td>
            <td> */}
          {props.data.id > 0 ? (
            <button
              id={"email" + props.data.id}
              type="button"
              className="btn btn-xs ms-2"
              title="Gerar PDF"
              onClick={gerarPDF}
              style={{ backgroundColor: "#F0BC74" }}
            >
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" strokeWidth="1.5" onClick={gerarPDF}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                class="icon icon-xs"
                onClick={gerarPDF}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            </button>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </td>
        <td>{props.data.id}</td>
        <td>{props.data.nome}</td>
        {/* <td>
                {props.data.email}
            </td> */}
        {/* <td>
                {props.data.celular}
            </td> */}
        <td>{props.data.cidade}</td>
        <td>{props.data.uf}</td>
        {/* <td>
                {props.data.pais}
            </td>
            <td>
                {props.data.barco_possui}
            </td>
            <td>
                {props.data.barco_modelo}
            </td>
            <td>
                {props.data.barco_tamanho}
            </td>
            <td>
                {props.data.barco_marca}
            </td>
            <td>
                {props.data.barco_local}
            </td> */}
      </tr>

      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default ContatoRow;
